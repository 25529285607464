import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";

import Button from "../../atoms/button";

import WarningIcon from "../../../resources/images/warning.png";

//director
import SyncDirector from "../../../resources/images/Sinc-Aspirante-Validador.png";
import CoordinatorDirector from "../../../resources/images/coodinator-director.png";
import Genial from "../../../resources/images/Genial.png";
import ScreenTools from "../../../resources/images/screen-tools.png";

//end of director

import Group873 from "../../../resources/images/Group873.png";
import Group874 from "../../../resources/images/Group874.png";

import LearningZone from "../../../resources/images/Zona_aprendizaje.png";
import GoodHandDirector from "../../../resources/images/good-hand-director.png";

import CalendarIcon from "../../../resources/images/calendar-icon.png";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";

import GiftSwitch from "../../molecules/gift-switch";
import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";
import { GridGiftSwitch } from "../../molecules/grid-gift-switch";

import { CardCopyKey } from "../../molecules/card-copy-key";

import { Toggle } from "../../atoms/switch";
import { getCaptainAndHostWithAspirantsByDirector } from "../../../middleware/services/useQueryListOfNewHosts";

const CaptainInductionDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
}) => {
  const { challengeName, idChallenge } = useParams();
  const { user } = useContext(UserContext);

  const [hostData, setHostData] = useState(null);
  const [hostActive, setHostActive] = useState([]);

  useEffect(() => {
    getListOfNewHosts();
  }, []);
  const getListOfNewHosts = async () => {
    await axios
      .get(`${API.LIST_OF_NEW_HOSTS.url}/${user?._id}?action=LIST_OF_NEW_HOSTS`)
      .then((response) => {
        setHostData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const putAddToWhatSapp = (idHost, status, e) => {
    let dataUpdate = {
      status: status,
      property: "addedToWhatsappGroup",
    };

    axios
      .put(
        `${API.ADD_TO_WHATSAPP_GROUP.url}/${idHost}?action=update-property-host`,
        dataUpdate
      )
      .then((response) => {
        if (response.status === 200) {
          setHostActive((prev) => {
            if (status) {
              if (!prev.includes(idHost)) {
                return [...prev, idHost];
              }
            } else {
              return prev.filter((host) => host !== idHost);
            }
            return prev;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [dataDirector, setDataDirector] = useState(null);
    useEffect(() => {
      fetchDataCaptain();
    }, []);
  
    const fetchDataCaptain = async () => {
      const listDirector = await getCaptainAndHostWithAspirantsByDirector(
        user?._id
      );
  
      setDataDirector(listDirector);
    };
  console.log("hostData")
  console.log(hostData)

  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <img
              src={SyncDirector}
              alt="sync-aspirante-director"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph align="center" size="sx">
              Para completar este desafío debes asegurar
              <br />
              que tu Coordinador(a) está sincronizado(a) con
              <br />
              sus Anfitriones y contigo.
            </Paragraph>
            <Paragraph align="center" size="sx">
              Ingresa al grupo de WhatsApp para cada EPA y<br />
              verifica que tanto los dos Anfitriones como el(la)
              <br />
              Coordinador(a) han enviado la captura de
              <br />
              pantalla correspondiente.
              <br />
              <br />A continuación vas a encontrar dos <b>switch</b> que
              <br /> deben estar activados para poder continuar con
              <br />
              tus actividades.
            </Paragraph>
            <br />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <img
              src={CoordinatorDirector}
              alt="coordinator-director"
              width="150"
              height="150"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph align="center" size="s">
              <b>
                Luis Carlos <br /> Sarmiento Angulo
              </b>
              <br />
              Coordinador(a).
            </Paragraph>
            <div
              className="user-card"
              style={{
                maxWidth: "365px",
                width: "345px",
                height: "61px",
              }}
            >
              <div className="user-info">
                Se encuentra sincronizado con
                <br /> sus Anfitriones.
              </div>
              <div className="toggle-switch">
                <Toggle
                  value={true}
                  className="switch-input"
                  //onClick={() => hadlerToggle(true)}
                />
              </div>
            </div>
            <span
              style={{
                maxWidth: "365px",
                width: "355px",
                textAlign: "center",
                fontSize: "14px",
                opacity: "0.7",
                marginBottom: "18px",
              }}
            >
              Este switch se activa automáticamente cuando el(la)
              <br />
              Coordinador(a) termine su Desafío A.
            </span>
            <div
              className="user-card"
              style={{
                maxWidth: "365px",
                width: "345px",
                height: "61px",
              }}
            >
              <div className="user-info">
                Se encuentra sincronizado
                <br /> contigo.
              </div>
              <div className="toggle-switch">
                <Toggle
                  value={true}
                  className="switch-input"
                  //onClick={() => hadlerToggle(true)}
                />
              </div>
            </div>
            <span
              style={{
                maxWidth: "365px",
                width: "355px",
                textAlign: "center",
                fontSize: "14px",
                opacity: "0.7",
                marginBottom: "18px",
              }}
            >
              Este switch debes activarlo manualmente cuando
              <br />
              verifiques las capturas de pantalla.
            </span>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              <b>¡Genail!</b>
              <br />
              <br />
              Has completado el <b>Desafío A</b>.
            </Paragraph>
            <img
              src={Genial}
              alt="Genial"
              style={{
                marginTop: "5px",
              }}
              width="226"
              height="226"
            />
            <Paragraph
              type="secondary"
              withBackground={true}
              size="s"
              align="center"
              className="paragraph-induction"
            >
              Ahora que ya realizaste las actividades, <br />
              continúa con el siguiente ▶️ VIDEO, para <br />
              seguir con tu <b>Inducción</b>.
              <br />
              <br />
              👋🏻 <b>Ya nos vemos.</b>
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Ver video
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <img
              src={ScreenTools}
              alt="tools"
              width="210"
              height="200"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph size="sx" align="center">
              Este Desafío se completa una vez tus
              <br />
              Coordinadores hayan hecho el entrenamiento
              <br />
              del ABC Convocatoria simulando ser un <br />
              “Aspirante 0” de sus respectivos Anfitriones en
              <br />
              la Plataforma.
              <br />
              <br />
              En ese momento habrán terminado el
              <br />
              Entrenamiento ABC Convocatoria de los
              <br />
              Anfitriones y estos ya estarán listos para
              <br />
              empezar a convocar a sus Validadores.
            </Paragraph>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: " min-content",
                gap: "19px",
                marginTop: "39px",
              }}
            >
              <GridGiftSwitch>
                {hostData && (
                  <div className="gift-switch-host">
                    <GiftSwitch
                      role="coordinator"
                      nameRole={"Coordinador(a)"}
                      name={toLowercaseAndCapitalize(hostData?.[0]?.name)}
                      range={7}
                      items={[
                        {
                          image: CalendarIcon,
                          title: "Prueba",
                          wait:
                            !hostData?.[0]?.schedulingTrainingAbcCoordinator ===
                            true
                              ? true
                              : false,
                          width: "24px",
                        },
                      ]}
                      onClick={(e) =>
                        putAddToWhatSapp(
                          hostData?.[0]?._id,
                          !hostActive.includes(hostData?.[0]?._id),
                          e
                        )
                      }
                    />
                  </div>
                )}
              </GridGiftSwitch>
            </div>
            <Paragraph align="center" size="sx">
              Tu Coordinador(a) aún no se encuentra
              <br />
              agendado(a) como parte del Entrenamiento
              <br />
              ABC Convocatoria de sus Anfitriones.
            </Paragraph>
            <span
              style={{
                maxWidth: "365px",
                width: "355px",
                textAlign: "center",
                fontSize: "14px",
                opacity: "0.7",
                marginTop: "26px",
              }}
            >
              Podrás continuar una vez el agendamiento se haya
              <br /> realizado.
            </span>
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              className="host-induction-day-paragraph"
            >
              ¡Genial!
              <br />
              <br />
              Has completado el <b>Desafío B.</b>
            </Paragraph>

            <img
              src={Genial}
              alt="Clap Hand"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph
              type="secondary"
              withBackground={true}
              size="s"
              align="center"
              className="paragraph-induction"
            >
              Ya puedes continuar con el<b> Desafío C</b> del Día de{" "}
              <b>Inducción.</b>
              <br />
              <br />
              👋🏻 <b>Ya nos vemos.</b>
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío C" && (
        <>
          <Step order={1} visibility={step === 1 ? "visible" : "hidden"}>
            <img
              src={WarningIcon}
              alt="induction day"
              width="100"
              height="100"
              style={{
                marginTop: "39px",
              }}
            />
            <Paragraph size="sx" align="center">
              Recuerda que hoy los Anfitriones podrán
              <br />
              habilitar el Plan B que consiste en la posibilidad
              <br />
              de volver a aplicar el ABC Filtro por parte de los
              <br />
              Anfitriones, lo que permitirá a los Coordinadores
              <br />
              y sus Anfitriones tener nuevos Validadores en la
              <br />
              Plataforma hoy mismo. Estos nuevos
              <br />
              Validadores podrán ser convocados y<br /> agendados al
              <b>
                {" "}
                EPA hasta el Día de Reglas del
                <br /> Juego.
              </b>
              <br />
              <br />
              En el<b> Desafío C</b> de los Anfitriones podrán
              <br />
              agregar dos validaciones adicionales por cada
              <br />
              cupo disponible.
            </Paragraph>

            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step order={2} visibility={step === 2 ? "visible" : "hidden"}>
            <Paragraph align="center" size="s">
              <b>Zona de Aprendizaje.</b>
            </Paragraph>
            <Paragraph align="center" size="sx">
              En tu rol como Director(a) debes estar pendiente del Desempeño de
              tus dos equipos, cuentas con el menú <b>Coordinadores</b>, ahí
              podrás revisar la cantidad de Aspirantes agendados para cada EPA.
              <br />
              <br />A continuación te enseñamos cómo.
            </Paragraph>
            <img src={LearningZone} alt="learning zone" width="246" height="246" />
            <Button
              onClick={() => {
                setStep(step + 1);
              }}
              styles={{
                position: "relative",
                marginTop: "75px",
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={3} visibility={step === 3 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Dirígete a la pestaña <b>Coordinadores</b> en el menú
              <br /> que encuentras en la parte superior.
            </Paragraph>

            <img src={Group873} alt="group-873" widh="342" height="144" />
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
              Luego encontrarás a tus Coordinadores, dando<br/>
              clic en alguno de ellos podrás acceder a sus<br/>
              Anfitriones y a los aspirantes de sus Anfitriones.
            </Paragraph>
            <img src={Group874} alt="group-874" widh="343" height="209" />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
  
          <Step order={4} visibility={step === 4 ? "visible" : "hidden"}>
            <Paragraph
              size="s"
              align="center"
              style={{
                marginTop: "39px",
                maxWidth: "364px",
              }}
            >
             <b>¡Vas súper!</b> 
            </Paragraph>
            <Paragraph
              size="s"
              align="center"
              style={{   maxWidth: "364px",
              }}
            >
             Te queda poco para completar el <b>Desafío C.</b>
            </Paragraph>
            <img src={GoodHandDirector} alt="good-hand-director" widh="343" height="209" />
            <CardCopyKey
              phrase="Desafío C Director"
              nameChallenge="Desafío C"
            />
            <Button
              styles={{
                position: "relative",
                marginTop: "96px",
              }}
              onClick={() => finishChallenge(null)}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CaptainInductionDay;

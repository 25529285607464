import { useNavigate, useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import CheckList from "../../../resources/images/check-icon.png";
import ClapHand from "../../../resources/images/clap-hand-coordinator.png";
import CheckHand from "../../../resources/images/Excelente.png";
import NewHost from "../../../resources/images/new-host.png";
import Candidate from "../../../resources/images/Candidate.png";
import CandidateBlack from "../../../resources/images/candidate_black.png";

import EpaBaseCoordinator from "../../../resources/images/epa-base-coordinator.png";
import ConfirmHostGif from "../../../resources/images/confirm-host-gif.png";
import { ReactComponent as AvatarMenu } from "../../../resources/images/circular-progress.svg";

import coordinatorSync from "../../../resources/images/coordinator-sync.png";
import Calendar1 from "../../../resources/images/calendar1.png";
import clock2 from "../../../resources/images/clock2.png";
import cash2 from "../../../resources/images/cash2.png";
import WarningIcon from "../../../resources/images/warning.png";
import NewCoordinator from "../../../resources/images/new-coordinator.png";
import ToolsScreenCoordinator from "../../../resources/images/tools-screen-coordinator.png";
import Button from "../../atoms/button";
import MedalSwitch from "../../molecules/medal-switch";
import { ReactComponent as TimeManagementMedal } from "../../../resources/images/time-management-medal.svg";
import { ReactComponent as JustTimeManagementMedal } from "../../../resources/images/just-in-time-coordinator.svg";
import whatsApp from "../../../resources/images/whatsApp.png";
import whatsAppGroup from "../../../resources/images/whatsAppGroup.png";
import Treesdicons from "../../../resources/images/3dicons.png";

import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import Checkbox from "../../atoms/checkbox";
import Modal from "../../atoms/modal";
import { API, ROLES } from "../../../middleware/utils/constants";

import Card from "../../atoms/card";
import CopyField from "../../atoms/copy-field";
import axios from "axios";
import AvatarChallengesMarked from "../../molecules/avatar-challenges-marked";
import JustInTime from "../../../resources/images/just-in-time-coordinator.svg";
import Grupo547 from "../../../resources/images/Group547.png";
import TouchScreenCoordinator from "../../../resources/images/touch-screen-coordinator.png";
import GoodHandCoordinator from "../../../resources/images/good-hand-coordinator.png";
import GoodHandClap from "../../../resources/images/clap-hand-coordinator.png";
import PauseIcon from "../../../resources/images/pause.png";
import ArrowSmall from "../../../resources/images/Arrow-small.png";
import {
  getCaptainAndHostWithAspirants,
  postAwardindMedalsToTheRooms,
} from "../../../middleware/services/epaCoordinatorServices";
import {
  assignMedal,
  getHostWinners,
} from "../../../middleware/services/common";
import CustomDatePicker from "../../molecules/date-picker";
import {
  formatDate,
  formatearDate,
  getHoraAmAndPm,
  reverseDate,
  reverseFomatDate,
  subtractDays,
} from "../../../middleware/utils/formatDate";

import { QueriesEpaDateSaveDateAvailable } from "../../../middleware/services/useQueryEpaSaveDateAvailableServices";
import { ScheduleTemp, simulationData } from "./dataTemp";
import { NewCoordinatorsSchedule } from "../director-epa-end-day/NewCoordinatorsSchedule";
import Vector from "../../../resources/images/Vector.png";
import CheckoutList from "../../../resources/images/checkout_list.png";
import { toLowercaseAndCapitalize } from "../../../middleware/utils/toLowercaseAndCapitalize";
import { Toggle } from "../../atoms/switch";
import Book from "../../../resources/images/book.png";
import TableNewDirector from "../../../resources/images/table_new_director.png";
import { CardCopyKey } from "../../molecules/card-copy-key";
import { getCaptainAndHostWithAspirantsByDirector } from "../../../middleware/services/useQueryListOfNewHosts";
import MenuUserInfo from "../../molecules/menu-user-info";
import "./dateMondaHou.scss";
const circle = {
  host: "⚪",
  "new host": "⚪",
  coordinator: "🔴",
  "new coordinator": "🔴",
  director: "🟡",
  captain: "🔵",
};
let titleRoleCoordinatorDiretor = [
  "Lista de Espera Coordinador(a)  🔴 (Tú)",
  "Lista de Espera  Director(a) 🟡",
  "Lista de Espera  Capitan(a) 🔵",
];
const CoordinatorAlignmentDay = ({
  finishChallenge,
  step,
  setStep,
  setChangeRole,
  setChangeDay,
  challenge,
}) => {
  const { challengeName, idChallenge } = useParams();
  const navigate = useNavigate();

  const {
    user,
    setscheduleParam,

    setUserTemp,
  } = useContext(UserContext);

  const [medals, setMedals] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(null);
  const [
    epaDateCoordinatingSchedulesById,
    setEpaDateCoordinatingSchedulesById,
  ] = useState([]);

  const [modalConfirmation, setModalConfirmation] = useState({
    isOpen: false,
    value: "",
  });

  const [idCoordinator, setIdCoordinator] = useState("");
  const [gifts, setGifts] = useState([]);

  const [calculatePurcheseJeunesse, setCalculatePurcheseJeunesse] =
    useState(false);

  const [aspirantsWithTraining, setAspirantsWithTraining] = useState([]);
  const [gameOverModal, setGameOverModal] = useState(false);
  const [canFinish, setCanFinish] = useState(false);
  const [hostWinners, setHostWinners] = useState([]);
  const [dateSelected, setDateSelected] = useState(false);
  const [dateBuySelected, setDateBuySelected] = useState(false);
  const [captainAndHostTemp, setCaptainAndHostTemp] = useState(null);
  const selectGift = (name, e) => {
    if (e.target.checked) {
      setGifts([
        ...gifts,
        {
          name,
          selected: e.target.checked,
        },
      ]);
    } else {
      setGifts(gifts.filter((item) => item.name !== name));
    }
  };
  const [dataDirector, setDataDirector] = useState(null);
  useEffect(() => {
    fetchDataCaptain();
  }, []);

  const fetchDataCaptain = async () => {
    const listDirector = await getCaptainAndHostWithAspirantsByDirector(
      user?._id
    );

    setDataDirector(listDirector);
  };

  useEffect(() => {
    axios
      .post(
        `${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}/?action=SAVE_SITUATIONS_TO_CHANGE`
      )
      .then((response) => {
        if (response.status === 200) {
          setCalculatePurcheseJeunesse(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${API.GET_CALCULATE_PURCHASE.url}?action=GET_CALCULATE_PURCHASE`)
      .then((response) => {
        if (response.status === 200) {
          setCalculatePurcheseJeunesse(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${API.LIST_CANDIDATES_WITH_TRAINING.url}/${user?._id}?action=GET_ASPIRANTS_WITH_TRAINING`
      )
      .then((response) => {
        if (response.status === 200) {
          setAspirantsWithTraining(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [captainAndHosts, setCaptainAndHosts] = useState([]);
  useEffect(() => {
    fetchData(user?._id);
    setEpaDateCoordinatingSchedulesById(simulationData);
  }, [user, simulationData]);

  const fetchData = async (idUser) => {
    const data = await getCaptainAndHostWithAspirants(idUser);

    setCaptainAndHosts(data);

    const hostWinnersList = await getHostWinners(user?._id, user?.role);
    setHostWinners(hostWinnersList);
  };

  const roleMapping = {
    "new director": "new captain",
    coordinator: "new director",
    host: "new coordinator",
    candidate: "new host",
  };

  const saveResponse = () => {
    const data = {
      questions: dataQuestionOptions,
      action: "SAVE_SITUATIONS_TO_CHANGE",
    };

    axios
      .post(
        `${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}/`,
        data
      )
      .then((response) => {
        if (response.status === 201) {
          finishChallenge(null);
          navigate("/missions");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateRoles = () => {
    captainAndHosts.forEach((item) => {
      // Actualizar el role del objeto actual
      if (roleMapping[item.role]) {
        item.role = roleMapping[item.role];
      }
      // Actualizar los roles en challenges si existen
      if (item.challenges) {
        item.challenges.forEach((challenge) => {
          if (roleMapping[challenge.role]) {
            challenge.role = roleMapping[challenge.role];
          }
        });
      }
      // Si tiene children, llamar recursivamente
      // if (item.children) {
      //   updateRoles(item.children);
      // }
    });

    setCaptainAndHostTemp(captainAndHosts);
  };

  const lengthDate = 4;

  const [dataDateTemp, setDataDateTemp] = useState(
    Array.from({ length: lengthDate }, (_, index) => ({
      index,
      date: "",
      hour: "",
    }))
  );
  const [openPickers, setOpenPickers] = useState({
    datePickers: Array(lengthDate).fill(false),
    timePickers: Array(lengthDate).fill(false),
  });

  const toggleDatePicker = (index) => {
    setOpenPickers((prev) => ({
      ...prev,
      datePickers: prev.datePickers.map((isOpen, i) =>
        i === index ? !isOpen : isOpen
      ),
    }));
  };
  const toggleTimePicker = (index) => {
    setOpenPickers((prev) => ({
      ...prev,
      timePickers: prev.timePickers.map((isOpen, i) =>
        i === index ? !isOpen : isOpen
      ),
    }));
  };
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [dateEdit, setDateEdit] = useState(new Date());
  const [monyEdit, setMonyEdit] = useState(0);
  const [timeEdit, setTimeEdit] = useState(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const [isDatePickerBuyOpen, setIsDatePickerBuyOpen] = useState(false);
  const [isTimePickerBuyOpen, setIsTimePickerBuyOpen] = useState(false);
  const [timeSelected, setTimeSelected] = useState(false);
  const [selectedZero, setSelectedZero] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  useEffect(() => {
    getCandidatAplicatProgressPoint(user?._id, "alignment day", 1);
  }, []);

  const getCandidatAplicatProgressPoint = async (idUser, day, opt) => {
    const params = day ? { day } : undefined;
    try {
      const response = await axios.get(
        `${API.PROGRESS_POINT.url}/${idUser}/?action=PROGRESS_POINT`,
        {
          params,
        }
      );
      if (response.status === 200) {
        setDataQuestionOptions(response.data.data[1].questions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [dataQuestionOptions, setDataQuestionOptions] = useState([]);
  const handleDateChangeEdit = (newTime) => {
    setDateEdit(newTime);
    setIsDatePickerOpen(false);
    setDateSelected(true);

    setSelectedDates({
      date: dateEdit,
      hour: timeEdit,
    });
  };
  const handleDateChangeBuyEdit = (newTime) => {
    setDateEdit(newTime);
    setIsDatePickerBuyOpen(false);
    setDateBuySelected(true);
    setSelectedBuyDates({
      idUser: "",
      purchaseDate: dateEdit,
      shoppingTime: timeEdit,
      amount: monyEdit,
    });
  };
  const handleDateChangeBuy = (monay) => {
    setMonyEdit(monay);
    setIsDatePickerBuyOpen(false);
    setDateBuySelected(true);
    setSelectedBuyDates({
      idUser: "",
      purchaseDate: dateEdit,
      shoppingTime: timeEdit,
      amount: monay,
    });
  };
  const handleTimeChangeEdit = (newTime) => {
    setTimeEdit(newTime);
    setIsTimePickerOpen(false);
    setTimeSelected(true);
    setSelectedDates({
      date: dateEdit,
      hour: timeEdit,
    });
  };
  const [timeBuySelected, setTimeBuySelected] = useState(false);
  const handleTimeChangeBuyEdit = (newTime) => {
    setTimeEdit(newTime);
    setIsTimePickerBuyOpen(false);
    setTimeBuySelected(true);
    setSelectedBuyDates({
      idUser: "",
      purchaseDate: dateEdit,
      shoppingTime: timeEdit,
      amount: monyEdit,
    });
  };
  const handleDateChange = (newTime, index) => {
    setDataDateTemp((prevState) =>
      prevState.map((item, i) =>
        i === index
          ? {
              ...item,
              date: newTime,
            }
          : item
      )
    );
    toggleDatePicker(index);
    setDate(newTime);
    setIsDatePickerOpen(false);
    // setDataDateTemp(
    //   Array.from({ length: lengthDate }, (_, index) => ({
    //     index,
    //     date: "",
    //     hour: "",
    //   }))
    // );
  };

  const handleTimeChange = (newTime, index) => {
    setDataDateTemp((prevState) =>
      prevState.map((item, i) =>
        i === index
          ? {
              ...item,
              hour: newTime,
            }
          : item
      )
    );
    toggleTimePicker(index);

    setIsTimePickerOpen(false);
  };

  const mutationEpaDateSaveDateAvailable = QueriesEpaDateSaveDateAvailable(
    user._id
  );

  const [acceptDate, setAcceptDate] = useState({
    data: [],
  });

  const [nameCoordinator, setNameCoordinator] = useState("");
  const [acceptedItems, setAcceptedItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [inputDate, setInputDate] = useState({
    date: "",
    hour: "",
    status: true,
  });

  const handlerAccept = (row, id, name, index) => {
    setIdCoordinator(id);
    setNameCoordinator(name);
    setInputDate({
      date: row.date,
      hour: row.hour,
      status: true,
    });
    setAcceptedItems((prevAcceptedItems) => [...prevAcceptedItems, id]);

    setModal(true);
  };
  const handlerEdit = (row, id) => {
    setscheduleParam(row);
    setIdCoordinator(id);
    setStep(step + 3);
  };
  const handlerNext = () => {
    mutationEpaDateSaveDateAvailable.mutate(acceptDate);

    setStep(step + 1);
  };
  const handlerNextMultiDate = () => {
    dataDateTemp.forEach((dateSave) => {
      let data = {
        data: [
          {
            date: reverseFomatDate(dateSave.date),
            hour: getHoraAmAndPm(dateSave.hour),
            status: true,
          },
        ],
      };
      mutationEpaDateSaveDateAvailable.mutate(data);
    });
    //setStep(step + 1);
  };
  const changeCoordinatorToNewDirector = () => {
    setUserTemp(user);

    setStep(step + 1);
  };

  const [accept, setAccept] = useState(false);
  const handlerSaveDate = (inputDate) => {
    setAcceptDate({ data: [...acceptDate.data, inputDate] });
    setAccept(true);
    setModal(false);
  };
  const handlerDeleteDate = (row) => {
    setAccept(false);
    setModal(false);
  };
  const [acceptData, setAcceptData] = useState();
  const [modalSchedule, setModalSchedule] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const [checked, setChecked] = useState(false);
  const [testgifmodal, setTestGifmodal] = useState(false);

  const [selectedBuyDates, setSelectedBuyDates] = useState({
    idUser: user?._id,
    purchaseDate: "",
    shoppingTime: "",
    amout: 0,
  });
  const [selectedDates, setSelectedDates] = useState({
    date: "",
    hour: "",
    status: false,
  });
  const schuduleTemp = () => {
    captainAndHosts.forEach((item) => {
      if (item?._id === user?._id) {
        item.children.forEach((child, childIndex) => {
          const scheduleForChild = ScheduleTemp.map((schedule) => ({
            ...schedule,
          }));

          scheduleForChild.reverse().forEach((itemSchedule) => {
            if (itemSchedule.day && dataDateTemp[childIndex]) {
              itemSchedule.date = formatearDate(dataDateTemp[childIndex].date);
              itemSchedule.hour = getHoraAmAndPm(dataDateTemp[childIndex].hour);
            }
          });

          const inductionDay = scheduleForChild.find(
            (s) => s.day === "Día de Inducción"
          );
          if (inductionDay) {
            inductionDay.date = subtractDays(inductionDay.date, 8);

            scheduleForChild.forEach((schedule) => {
              const inductionDayEpa = scheduleForChild.find(
                (s) => s.day === "EPA"
              );
              if (schedule.day === "Día de Alineamiento") {
                schedule.date = subtractDays(inductionDayEpa.date, 1);
              }
              if (schedule.day === "Día de Filtro") {
                schedule.date = subtractDays(inductionDayEpa.date, 2);
              }
              if (schedule.day === "Día 3") {
                schedule.date = subtractDays(inductionDayEpa.date, 3);
              }
              if (schedule.day === "Día 2") {
                schedule.date = subtractDays(inductionDayEpa.date, 4);
              }
              if (schedule.day === "Día 1") {
                schedule.date = subtractDays(inductionDayEpa.date, 5);
              }
              if (schedule.day === "Día de Reglas") {
                schedule.date = subtractDays(inductionDayEpa.date, 7);
              }
              // if (daysToAdjust.includes(schedule.day)) {
              //   schedule.date = subtractDays(inductionDayEpa.date, 1);
              // }
            });
          }
          child.schedule = scheduleForChild.reverse();
        });
      }
    });
    setStep(step + 1);
  };

  const changeDateRuleHandler = (date) => {
    captainAndHosts.forEach((newDirectir) => {
      if (newDirectir?._id === user?._id) {
        newDirectir.children.forEach((child, childIndex) => {
          if (child?._id === idCoordinator) {
            child.schedule.forEach((event) => {
              if (event.day === "Día de Reglas") {
                event.date = date;
              }
            });
          }
        });
      }
    });
  };
  const onClose = () => {
    setModalSchedule(false);
  };
  const handleSend = async () => {
    captainAndHosts.forEach((newDirectir) => {
      if (newDirectir?._id === user?._id) {
        newDirectir.children.forEach((child, childIndex) => {
          if (child?._id === idCoordinator) {
            child.schedule.forEach((event) => {
              if (event.day === "EPA") {
                event.date = formatearDate(selectedDates?.date);
                event.hour = getHoraAmAndPm(selectedDates?.hour);
              }
            });
          }
        });
      }
    });
    setStep(step - 3);
  };
  const [value, setValue] = useState(false);
  const hadlerToggle = (id) => {
    setValue(true);
  };

  const [selectedGift, setSelectedGift] = useState([]); // Cambiamos a un array para manejar múltiples seleccionados
  const handlerGift = (gift, children) => {
    const updatedSelection = [...selectedGift];

    const isAlreadySelected = updatedSelection.find(
      (item) => item.id === gift._id
    );

    if (isAlreadySelected) {
      const filteredSelection = updatedSelection.filter(
        (item) =>
          item.id !== gift._id &&
          !children.some((child) => child._id === item.id)
      );
      setSelectedGift(filteredSelection);
    } else {
      const newSelection = [
        ...updatedSelection,
        { id: gift._id, status: gift.giftGiving, property: "string" },
        ...children.map((child) => ({
          id: child._id,
          status: child.role,
          property: "child",
        })),
      ];
      setSelectedGift(newSelection);
    }
  };

  const [userInfo, setUserInfo] = useState();
  const [modalValidate, setModalValidate] = useState(false);
  const handlerValidators = async (host) => {
    setUserInfo(host.personalReferences);
  };
  useEffect(() => {
    selectedBuyDates.idUser = user?._id;
  }, []);
  const handlerSaveBuy = (idUser) => {
    const data = {
      idUser: idUser,
      purchaseDate: selectedBuyDates?.purchaseDate,
      shoppingTime: selectedBuyDates?.shoppingTime,
      amount: Number(selectedBuyDates?.amount),
    };

    axios
      .post(`${API.PROGRAMMING_PAYMENT_COORDINATOR.url}`, {
        ...data,
        action: "PROGRAMMING_PAYMENT_COORDINATOR",
      })
      .then((response) => {
        if (response.status === 201) {
          setStep(step + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleNavigation = (plus) => {
    const navigation = currentSlide + plus;
    if (navigation < 3 && navigation >= 0) {
      setCurrentSlide(navigation);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };
  const selectedCandidate = (id) => {
    setSelectedCandidates((prev) => [...prev, id]);
  };
  const [selectedIdHost, setSelectedIdHost] = useState("");
  const [hostByPersonalReferences, setHostByPersonalReferences] = useState({
    selectedIdHost: [],
  });
  const [director, setDirector] = useState([]);
  console.log("dataDirector");
  console.log(dataDirector);
  return (
    <>
      {challengeName === "Desafío A" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="coordinator-alignment-day-container"
          >
            <img
              src={EpaBaseCoordinator}
              alt="EPA Base"
              className=" marginTop"
            />
            <Paragraph size="sx" align="center">
              <br />
              <b>¡Prepárate para el EPA!</b>
              <br />
              <br />
              Ahora vas a tener la oportunidad de:
              <br />
              <br />
              Familiarizarte con la Visualización de la tabla donde se muestra
              si tus Anfitriones le han otorgado la medalla de{" "}
              <b>Manejo del Tiempo</b>
              🥇 correctamente a sus Aspirantes en su participación cuando estén
              en el EPA.
            </Paragraph>
            <Button
              onClick={() => setStep(step + 1)}
              styles={{ position: "relative", marginTop: "80px" }}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="sx" align="center">
              <b>Módulo simluador de medallas.</b>
              <br />
              <br />
              Aquí podrás simular cómo se visualizan las medallas de los
              Aspirantes y a su vez cómo se otorgan las{" "}
              <b>Medallas de Manejo del Tiempo</b> 🥇 <br />a tus Anfitriones
              cuando estés en el EPA.
            </Paragraph>
            <div className="tutorial-time-management">
              <div className="tutorial-time-management__info">
                <TimeManagementMedal
                  className="coordinator"
                  width="33.75"
                  height="29.9"
                />
                <Paragraph size="sx" align="center">
                  Presiona SÍ, en el participante que haya ganado medalla de{" "}
                  <strong> Manejo del Tiempo </strong>
                  <br />y No, en el caso contrario.
                </Paragraph>
              </div>
              {captainAndHosts?.map(
                (candidate) =>
                  !candidate.role.startsWith("new") && (
                    <MedalSwitch
                      roleName={ROLES[candidate.role]?.roleName}
                      name={candidate.name}
                      circle={ROLES[candidate.role]?.circle}
                      disabled={candidate.disabled}
                      onClick={(value) => {
                        setMedals([...medals, { value, name: candidate.name }]);
                        assignMedal(
                          "timeManagement",
                          value,
                          candidate._id,
                          idChallenge
                        );
                      }}
                    />
                  )
              )}
            </div>

            <Paragraph size="sx" align="center">
              Podrás continuar una vez tu Director(a)
              <br /> confirme que se dieron o no, todas las medallas.
            </Paragraph>
            <Button
              onClick={() => setStep(step + 1)}
              className="coordinator"
              role={user?.role}
              disabled={medals.length === 0}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <br />
            <Paragraph size="s" align="center">
              <b>¡Felicitaciones!</b>
              <br /> Tu participación compartiendo tu PDL con <br />
              el equipo fue perfecta.
            </Paragraph>
            <div className="avatar-modal-coordinator-room-p">
              <img src={GoodHandCoordinator} alt="" />
            </div>

            <Paragraph size="s" align="center">
              ¡Has ganado una medalla de <b> Manejo del Tiempo!</b>
            </Paragraph>
            <div className="avatar-modal-coordinator-room-p">
              <img src={Grupo547} alt="" />
            </div>

            <Button
              styles={{
                position: "relative",
                marginTop: "80px",
              }}
            >
              Ver video
            </Button>
            <Button
              onClick={() => setStep(step + 1)}
              size="small"
              styles={{ position: "relative", marginTop: "40px" }}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={step === 4}
            className="confirmation-modal step epa-day-modal"
          >
            <img src={PauseIcon} alt="pause-icon" width={106} height={106} />
            <span className="title">¡Coordiandor(a)!</span>
            <Paragraph align="center" size="sx">
              Te encuentras en una pantalla de <b>Pausa Global</b>, ningún
              miembro del EPA podrá avanzar hasta que el(la) Director(a) lo
              permita.
              <br />
              <br />
              Procura estar atento a las indicaciones para completar las
              actividades de forma sincronizada.
            </Paragraph>

            <Button size="small" onClick={() => setStep(step + 1)}>
              ¡Vale!
            </Button>
          </Modal>
          <Step
            order={5}
            visibility={step === 5 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="sx" align="center">
              <b>
                ¡Coordinador(a)! Ahora vas a simular cómo dar Medallas en Equipo
                de Justo a Tiempo.
              </b>
            </Paragraph>
            <Paragraph size="sx" align="center">
              Para ganar esta medalla de equipo, tu Director(a) debió haber
              completado la pausa #4 de la Sala Épica máximo 30 minutos después
              de la hora de inicio del EPA.
            </Paragraph>
            <div className="tutorial-time-management">
              <div className="tutorial-time-management__info">
                <JustTimeManagementMedal
                  className="coordinator"
                  width="33.75"
                  height="29.9"
                />
                <Paragraph size="s" align="center">
                  Presiona SÍ, para otorgar la medalla en equipo de Justo a
                  Tiempo equipo; y NO, en caso contrario.
                </Paragraph>
              </div>

              {hostWinners?.[0]?.challenges.map(
                (host, index) =>
                  index < 3 && (
                    <MedalSwitch
                      name={host.nameChallenge}
                      disabled={index > 0}
                      onClick={(value) => {
                        setRooms([
                          ...medals,
                          { value, name: "Karime Castañeda" },
                        ]);
                        setModalConfirmation({
                          ...modalConfirmation,
                          value: value === "No" ? value.toUpperCase() : value,
                        });
                      }}
                    />
                  )
              )}
            </div>
            <Paragraph size="sx" align="center">
              Podrás continuar cuando tu Director(a) confirme que diste o no, la
              medalla en equipo.
            </Paragraph>

            <Button
              onClick={() =>
                setModalConfirmation({
                  ...modalConfirmation,
                  isOpen: true,
                })
              }
              role={user?.role}
              disabled={rooms.length === 0}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            className="host-alignment-day-container epa-confirmation-modal"
            isOpen={modalConfirmation?.isOpen}
            onClose={() => setModalConfirmation(false)}
          >
            <Paragraph size="s" align="center" className="p-small-0">
              ¿Estás seguro(a) que tu equipo {modalConfirmation?.value} ganó la
              medalla?
            </Paragraph>
            <br />
            <div className="buttons">
              <Button
                size="small"
                onClick={async () => {
                  const response = await postAwardindMedalsToTheRooms(
                    user?._id,
                    idChallenge
                  );
                  if (response?.success) {
                    setModalConfirmation({
                      ...modalConfirmation,
                      isOpen: false,
                    });
                    const finishTime = new Date().toLocaleTimeString("en-US", {
                      timeZone: "America/Bogota",
                      hour12: false,
                    });
                    setCurrentTime({
                      hours: finishTime.split(":")[0],
                      minutes: finishTime.split(":")[1],
                      seconds: finishTime.split(":")[2],
                    });
                    setStep(step + 1);
                  }
                }}
              >
                Sí
              </Button>
              <Button
                size="small"
                onClick={() =>
                  setModalConfirmation({
                    ...modalConfirmation,
                    isOpen: false,
                  })
                }
              >
                No
              </Button>
            </div>
          </Modal>
          <Modal isOpen={step === 6} className="confirmation-register-modal">
            <div className="confirmation-register-container">
              {currentTime?.hours === "09" && currentTime?.minutes === "00" && (
                <Paragraph align="center" size="s">
                  <b>¡Felicitaciones equipo ACDC!</b>
                  <br />
                  Lograron completar las actividades de la Sala Épica{" "}
                  <b>Justo a Tiempo.</b>
                </Paragraph>
              )}
              {currentTime?.hours === "09" && currentTime?.minutes === "00" && (
                <img
                  src={JustInTime}
                  alt="Just in Time"
                  style={{
                    opacity:
                      currentTime?.hours === "09" &&
                      currentTime?.minutes === "00"
                        ? "1"
                        : "0.5",
                  }}
                />
              )}
              {currentTime?.hours === "09" && currentTime?.minutes === "00" ? (
                <>
                  <Paragraph align="center" size="s">
                    ¡Has ganado una medalla en equipo de <b>Justo a Tiempo!</b>
                  </Paragraph>
                </>
              ) : (
                <>
                  <Paragraph align="center" size="s">
                    <b>¡Equipo ACDC!</b>
                    <br />
                    <br />
                    ¡Ups! Lamentablemente, no cumplieron con el tiempo
                    establecido.
                    <br />
                    En esta ocasión, no ganaron medalla en equipo de Justo a
                    Tiempo ¡Podrán mejorar su desempeño durante el EPA!
                  </Paragraph>
                </>
              )}
              <br />

              <Button size="small" onClick={() => setStep(step + 1)}>
                ¡Vale!
              </Button>
            </div>
          </Modal>
          <Step
            order={7}
            visibility={step === 7 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="s" align="center">
              <b>¡Genial!</b>
              <br />
              Has completado el <b>Desafío A</b>
            </Paragraph>
            <img src={ClapHand} alt="Clap Hand" className="good-hand" />
            <div className="tutorial-time-management">
              <div className="tutorial-time-management__info">
                <Paragraph size="s" align="center" className="background-p">
                  Ahora que ya hiciste la simulación de medallas del EPA,
                  continúa con el siguiente ▶️ VIDEO, para seguir con tu
                  Alineamiento
                  <br />
                  <br />
                  👋🏻 Ya nos vemos.
                </Paragraph>
              </div>
            </div>

            <Button onClick={() => finishChallenge(null)} className="marginTop">
              Ver video
            </Button>
          </Step>
        </>
      )}
      {challengeName === "Desafío B" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="s" align="center">
              <b>¡Prepárate para el EPA!</b>
            </Paragraph>
            <Paragraph size="xs" align="center">
              Ahora es el momento de definir tu disponibilidad de fecha y hora
              para los próximos 2 EPAs, en los que asumirás el rol de
              Director(a). Define 4 opciones para que los Anfitriones y sus{" "}
              <br />
              Aspirantes puedan seleccionar la que mejor se ajuste a sus
              horarios.
            </Paragraph>
            <Paragraph size="xs" align="center">
              Verifica tu disponibilidad en la agenda para cada opción.
            </Paragraph>

            <div className="checkbox-table">
              <div className="checkbox-table__header"></div>

              {[...Array(lengthDate)].map((_, index) => (
                <div className="checkbox-table__item" key={index}>
                  <CustomDatePicker
                    isOpen={openPickers.datePickers[index]}
                    onSelect={(newDate) => {
                      const newDateObj = new Date(newDate);

                      const fechaRepetida = dataDateTemp.some((item, idx) => {
                        const itemDate = new Date(item?.date); // Asegurarse de que es un objeto Date
                        return (
                          idx !== index &&
                          itemDate.toDateString() === newDateObj.toDateString()
                        );
                      });

                      if (fechaRepetida) {
                        alert(
                          "Esta fecha ya ha sido seleccionada. Por favor, elija otra."
                        );
                        return;
                      }

                      handleDateChange(newDateObj, index);
                    }}
                    time={dataDateTemp[index]?.date || new Date()}
                    max={
                      dataDateTemp[index]?.date
                        ? new Date(
                            new Date().setDate(new Date().getDate() + 21)
                          )
                        : new Date(
                            new Date().setDate(new Date().getDate() + 21)
                          )
                    }
                    min={
                      dataDateTemp[index]?.date
                        ? new Date(new Date().setDate(new Date().getDate() + 9))
                        : new Date(new Date().setDate(new Date().getDate() + 9))
                    }
                    onCancel={() => toggleDatePicker(index)}
                  />
                  <CustomDatePicker
                    isOpen={openPickers.timePickers[index]}
                    onSelect={(newTime) => handleTimeChange(newTime, index)}
                    time={dataDateTemp[index]?.hour || new Date()}
                    isTimePicker
                    onCancel={() => toggleTimePicker(index)}
                  />
                  <span
                    className="checkbox-table__item__start-time"
                    onClick={() => toggleDatePicker(index)}
                  >
                    {dataDateTemp[index]?.date
                      ? formatearDate(dataDateTemp[index]?.date)
                      : "Día/Mes/Año"}
                  </span>
                  <span
                    className="checkbox-table__item__end-time"
                    onClick={() => toggleTimePicker(index)}
                  >
                    {dataDateTemp[index]?.hour
                      ? getHoraAmAndPm(dataDateTemp[index]?.hour)
                      : "Hora"}
                  </span>
                </div>
              ))}
            </div>
            <Button
              onClick={() => {
                schuduleTemp();
                updateRoles();
                handlerNextMultiDate();
              }}
              disabled={
                dataDateTemp?.filter((item) => item?.hour !== "").length === 4
                  ? false
                  : true
              }
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="s" align="center">
              <b>Zona de Aprendizaje EPA.</b>
            </Paragraph>
            <img
              src={TouchScreenCoordinator}
              alt="touch-screen-coordinator"
              width={156}
              height={156}
            />
            <Paragraph size="sx" align="center">
              Ahora experimentarás una simulación que replica los desafíos que
              desbloquearás para tu nuevo rol al final del EPA.
              <br />
              <span style={{ color: "green" }}>
                Presta atención para que puedas prepararte de manera óptima para
                cuando llegue el momento.
              </span>
            </Paragraph>
            <Paragraph size="sx" align="center">
              ¡Tú serás el(la) Nuevo(a) Director(a)!
            </Paragraph>

            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>
          <Modal
            isOpen={step === 3}
            className="host-alignment-day-container epa-confirmation-modal"
          >
            <Paragraph size="s" align="center">
              <b>¡Coordinador(a)!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Primero vas a entrenarte en las actividades correspondientes al
              Desafío Honor que vivirás al final del EPA.
            </Paragraph>
            <Button
              size="small"
              onClick={() => {
                changeCoordinatorToNewDirector();
                setChangeRole("director");
                setChangeDay("Simulación Nuevo(a) Director(a)");
              }}
            >
              ¡Vale!
            </Button>
          </Modal>

          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="s" align="center">
              <b>Confirmación de fechas para el EPA.</b>
            </Paragraph>

            <Paragraph size="s" align="center">
              Es momento de definir la fecha y hora del EPA de los
              Coordinadores:
            </Paragraph>
            {captainAndHosts?.map(
              (NewDirector) =>
                NewDirector?._id === user?._id &&
                NewDirector?.children?.map((item, index) => (
                  <div
                    className="card-host card-coordinator-new"
                    key={item._id || index}
                  >
                    <div className="card-host-header">
                      <img
                        src={NewCoordinator}
                        alt="new-host"
                        width={80}
                        height={80}
                      />
                      <div className="coordinator-info">
                        <p className="coordinator-name text-lower-and-capitalize">
                          {toLowercaseAndCapitalize(item.name)}
                        </p>
                        <span className="status nuevo">
                          {circle.coordinator}Nuevo(a) Coordinador(a)
                        </span>
                      </div>
                    </div>
                    <div className="card-body">
                      <input
                        type="gridDatePicker"
                        className="epa-hungry-date "
                        value={
                          item?.schedule?.[7]?.date
                            ? item.schedule[7].date
                            : "Fecha no disponible"
                        }
                      />
                      <input
                        type="text"
                        className="epa-hungry-hour"
                        value={
                          item?.schedule?.[7]?.hour
                            ? item.schedule[7].hour
                            : "Hora no disponible"
                        }
                      />
                    </div>
                    <div className="card-btn">
                      <span
                        className="btn-date edit"
                        onClick={() =>
                          item?.schedule &&
                          handlerEdit(item.schedule, item?._id)
                        }
                      >
                        Editar
                      </span>
                      <span
                        className="btn-date accept"
                        onClick={() =>
                          item?.schedule?.[7] &&
                          handlerAccept(
                            item.schedule[7],
                            item?._id,
                            item.name,
                            index
                          )
                        }
                      >
                        {" "}
                        {acceptedItems.includes(item._id)
                          ? "Aceptado"
                          : "Aceptar"}
                      </span>
                    </div>
                  </div>
                ))
            )}
            <br />
            <br />
            <br />
            <Button
              className="director"
              onClick={handlerNext}
              disabled={acceptDate.data.length >= 2 ? false : true}
            >
              Continuar EPA
            </Button>
          </Step>
          <Modal isOpen={modal} className="agreements-modal">
            <br />
            <br />
            <Paragraph size="xs" align="center">
              ¿Estás seguro(a) que puedes asistir al EPA de tu Coordinador(a)?
              <br />
              <br />
              {nameCoordinator}
              <br />
              <br />
              <div className="card-body">
                <input
                  type="text"
                  className="epa-hungry-date"
                  value={inputDate.date}
                />
                <input
                  type="text"
                  className="epa-hungry-hour"
                  value={inputDate.hour}
                />
              </div>
            </Paragraph>
            <div className="card-btn">
              <Button
                className=" accept-modal "
                onClick={() => handlerSaveDate(inputDate)}
                size="small"
              >
                Si
              </Button>
              <Button
                className=" accept-modal "
                onClick={() => handlerDeleteDate(inputDate)}
                size="small"
              >
                No
              </Button>
            </div>
          </Modal>

          <Step
            order={5}
            visibility={step === 5 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <NewCoordinatorsSchedule
              setModalSchedule={setModalSchedule}
              setAcceptData={setAcceptData}
              setStep={setStep}
              step={step}
              setIdCoordinator={setIdCoordinator}
              captainAndHosts={captainAndHosts}
            />
            <br />
            <br />
            <br />
            <br />
            <Button className="director" onClick={() => setStep(step + 1)}>
              Continuar EPA
            </Button>
          </Step>
          <Modal isOpen={modalSchedule} className="agreements-modal">
            <span
              className="close icon-close close-new-director"
              onClick={() => onClose()}
              onKeyDown={() => {}}
            ></span>
            {acceptData?.map((event, index) => (
              <>
                <Paragraph size="s" align="center" className="event-modal-text">
                  <span
                    onClick={() => {
                      changeDateRuleHandler(event);
                      setModalSchedule(false);
                    }}
                  >
                    {event}
                  </span>
                </Paragraph>
              </>
            ))}
          </Modal>
          <Modal
            isOpen={step === 6}
            className="host-alignment-day-container epa-confirmation-modal"
          >
            <Paragraph size="s" align="center">
              <b>¡Coordinador(a)!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Ahora vas a entrenarte en las actividades correspondientes al{" "}
              <b>Desafío Humildad</b> que vivirás al final del EPA.
            </Paragraph>
            <Button size="small" onClick={() => setStep(step + 2)}>
              ¡Vale!
            </Button>
          </Modal>
          <Step
            order={7}
            visibility={step === 7 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <div className="event-container">
              <span className="title">
                Elige una de las posibles fechas disponibles
              </span>

              <div className="event-possible">
                <Checkbox
                  name="hold"
                  className="event-possible-checkbock"
                  checked={activeBtn}
                  onChange={(e) => setActiveBtn(e.target.checked)}
                />
                <div
                  className="event-possible-title"
                  onClick={(e) => setIsDatePickerOpen(true)}
                >
                  <span className="checkbox-table__item__start-time">
                    {dateSelected ? formatearDate(dateEdit) : "Crear fecha"}
                  </span>
                </div>
                <span
                  className="event-possible-hour"
                  onClick={(e) => setIsTimePickerOpen(true)}
                >
                  {timeSelected ? getHoraAmAndPm(timeEdit) : "0:00__"}
                </span>
                <CustomDatePicker
                  isOpen={isDatePickerOpen}
                  onSelect={handleDateChangeEdit}
                  time={dateEdit}
                  min={
                    new Date(
                      dateEdit.getFullYear(),
                      dateEdit.getMonth(),
                      dateEdit.getDate() + 8
                    )
                  }
                  max={
                    new Date(
                      dateEdit.getFullYear(),
                      dateEdit.getMonth(),
                      dateEdit.getDate() + 15
                    )
                  }
                  role="coordinator"
                  onCancel={() => setIsDatePickerOpen(false)}
                />
                <CustomDatePicker
                  isOpen={isTimePickerOpen}
                  onSelect={handleTimeChangeEdit}
                  time={timeEdit}
                  role="coordinator"
                  onCancel={() => setIsTimePickerOpen(false)}
                  isTimePicker
                />
              </div>

              <span className="event-possible-span">
                {" "}
                Elige una fecha del calendario{" "}
              </span>
            </div>
            <br />
            <br />
            <br />
            <br />
            {checked ? (
              <Button onClick={handleSend} disabled={!checked}>
                Aceptar Fecha
              </Button>
            ) : (
              <Button onClick={handleSend} disabled={!activeBtn}>
                Aceptar Fecha
              </Button>
            )}
          </Step>
          <Step
            order={8}
            visibility={step === 8 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <br />
            <br />
            <br />
            <img src={whatsApp} alt=" whatsapp" width="85" height="86" />
            <br />
            <br />
            <br />
            <Paragraph size="sx" align="center">
              En este momento los Nuevos Coordinadores, deben estar realizando
              la invitación y <br /> agregando al grupo de WhatsApp a los
              Nuevos(as) Anfitriones(as).
            </Paragraph>

            <Paragraph size="sx" align="center">
              Tú, como Nuevo(a) Director(a), estás encargado(a) de verificar que
              se agreguen correctamente.
            </Paragraph>
            <Button size="small">Ver video</Button>
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>

          <Step
            order={9}
            visibility={step === 9 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="sx" align="center">
              A continuación deberás activar el switch de <br />
              cada Nuevo(a) Anfitrión(a) para confirmar que ya se encuentran
              agregados al grupo de WhatsApp correspondiente.
            </Paragraph>

            {captainAndHosts &&
              captainAndHosts.map(
                (director) =>
                  director?._id === user?._id &&
                  director.children.map((coordinator) =>
                    coordinator.children.map((host) => (
                      <div className="user-card">
                        <div className="user-info">
                          <img
                            src={NewHost}
                            alt="new-host"
                            width={80}
                            height={80}
                            className="user-avatar"
                          />

                          <div className="user-details">
                            <span className="user-name">{host.name}</span>
                            <div className="user-status">
                              <span className="status-dot"></span>
                              <span className="status-text">
                                Nuevo(a) Anfitrión(a)
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="toggle-switch">
                          <Toggle
                            value={value}
                            className="switch-input"
                            onClick={() => hadlerToggle(true)}
                          />
                        </div>
                      </div>
                    ))
                  )
              )}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button
              onClick={() => setStep(step + 1)}
              disabled={!value}
              className="marginTop"
            >
              Continuar EPA
            </Button>
          </Step>
          <Step
            order={10}
            visibility={step === 10 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <br />

            <img src={TableNewDirector} alt="" />
            <Paragraph align="left" className="gift-text" size="s">
              “Las palabras de un líder y los pasos que da, deben coincidir.
              Como dice el dicho: “Tu palabra es tu valor y tu valor está en tu
              palabra.”
            </Paragraph>

            <div className="phrase-container phrase-container-height">
              <img src={Book} alt="Book" className="book book-top" />
              <Paragraph className="gift-text-phrase" size="s" align="left">
                Fragmento tomado del Libro
                <br />
                <b>Lanzando una Revolución sobre el Liderazgo.</b>
              </Paragraph>
            </div>
            <br />
            <br />
            <br />

            <Paragraph
              className="gift-text-phrase"
              size="s"
              align="left"
            ></Paragraph>
            <Button>Ver video</Button>
            <Button size="small" onClick={() => setStep(step + 1)}>
              Continuar
            </Button>
          </Step>
          <Step
            order={11}
            visibility={step === 11 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <span className="title">Confirmación Compras y obsequios</span>
            <div className="avatar-host">
              <img src={ConfirmHostGif} alt="" className="avatar-host-img" />
            </div>

            <span className="title-gift-span">¡Nuevo(a) Director(a)!</span>
            <Paragraph align="center" size="sx">
              En este momento los dos Nuevos Anfitriones se deben unir al canal
              de YouTube de ACDC con el obsequio 🎁 que recibirán por parte de
              los Nuevos(as) Coordinadores(as).
            </Paragraph>
            <Paragraph align="center" size="sx">
              Confirma que se dieron los obsequios en la siguiente pantalla
              usando como evidencia los pantallazos que te enviarán los
              Nuevos(a) Coordinadores(as).
            </Paragraph>

            <Button size="small" style="ghost">
              Manual de compra
            </Button>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button className="btn-gift-2" onClick={() => setStep(step + 1)}>
              Continuar
            </Button>
          </Step>
          <Step
            order={12}
            visibility={step === 12 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <br />
            {captainAndHosts &&
              captainAndHosts.map(
                (director) =>
                  director?._id === user?._id &&
                  director.children.map((coordinator) => {
                    const isCoordinatorSelected = selectedGift.some(
                      (item) => item.id === coordinator._id
                    );

                    return (
                      coordinator.paymentForAudiovisualContentAcdc ===
                        "APPROVED" &&
                      coordinator.paymentLevelsAcdcPlatform === "APPROVED" && (
                        <>
                          <div className="user-list-checkbox">
                            <div className="user-card-checkbox">
                              <div className="user-info-checkbox">
                                <img
                                  src={NewCoordinator}
                                  alt="new-host"
                                  width={80}
                                  height={80}
                                  className="user-avatar-checkbox"
                                />
                                <div className="user-details-checkbox">
                                  <span className="user-name-checkbox">
                                    {coordinator.name}
                                  </span>
                                  <p className="user-action-checkbox">
                                    Dió los obsequios{" "}
                                    <img
                                      src={Vector}
                                      alt="gitf"
                                      className="action-icon-checkbox"
                                    />
                                    <img
                                      src={Vector}
                                      alt="gitf"
                                      className="action-icon-checkbox"
                                    />
                                  </p>
                                </div>
                              </div>
                              <div className="coordinator-checkbox">
                                <Checkbox
                                  checked={isCoordinatorSelected}
                                  onChange={() =>
                                    handlerGift(
                                      coordinator,
                                      coordinator.children || []
                                    )
                                  }
                                />
                              </div>
                            </div>
                            {coordinator.children?.map((host) => {
                              const isHostSelected = selectedGift.some(
                                (item) => item.id === host._id
                              );

                              return (
                                <>
                                  <div className="user-card-checkbox">
                                    <div className="user-info-checkbox">
                                      <img
                                        src={NewHost}
                                        alt="new-host"
                                        width={80}
                                        height={80}
                                        className="user-avatar-checkbox"
                                      />
                                      <div className="user-details-checkbox">
                                        <span className="user-name-checkbox">
                                          {host.name}
                                        </span>
                                        <p className="user-action-checkbox-2">
                                          Se unió al Canal de Youtube
                                        </p>
                                      </div>
                                    </div>

                                    <div className="user-status-checkbox-2">
                                      <div className="status-check-checkbox-3">
                                        <img
                                          src={CheckoutList}
                                          alt="checkout-list"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </>
                      )
                    );
                  })
              )}

            <div className="checkbox-table"></div>
            <br />
            <br />
            <br />
            <br />
            <Button
              onClick={() => setTestGifmodal(true)}
              disabled={
                selectedGift.filter((item) => item.status === undefined)
                  .length == 2
                  ? false
                  : true
              }
              className="marginButton"
            >
              Continuar EPA
            </Button>
          </Step>
          <Modal
            isOpen={testgifmodal}
            className="agreements-gif-modal director"
          >
            <div className="avatar-modal-host">
              <img src={ConfirmHostGif} alt="" />
            </div>
            <span className="title-gift-span">¡Super!</span>
            <br />
            <Paragraph size="s" align="center">
              Los Anfitriones ya tienen acceso al Contenido y a la Plataforma
              ACDC con el regalo 🎁 que les hizo el Nuevo(a) Coordinador(a),
              ahora ell@s pueden acceder a los siguientes niveles para aplicar
              la estrategia. Mañana iniciarán con el día de Inducción junto con
              tu equipo.
            </Paragraph>
            <br />

            <Button
              className="btn-gift-0"
              onClick={() => {
                setTestGifmodal(false);
                setStep(step + 1);
              }}
            >
              ¡Vale!
            </Button>
          </Modal>
          <Modal
            isOpen={step === 13}
            className="host-alignment-day-container epa-confirmation-modal"
          >
            <Paragraph size="s" align="center">
              <b>¡Coordinador(a)!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
              Para finalizar vas a entrenarte en las <br /> actividades
              correspondientes al{" "}
              <b>
                Desafío <br />
                Hambre{" "}
              </b>
              que vivirás al final del EPA.
            </Paragraph>
            <Button size="small" onClick={() => setStep(step + 2)}>
              ¡Vale!
            </Button>
          </Modal>
          <Step
            order={14}
            visibility={step === 14 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <span className="title">¡Momento de los agradecimientos!</span>
            <img src={ToolsScreenCoordinator} alt="ToolsScreenCoordinator" />
            <Paragraph align="center" size="sx">
              💡 Nuevo(a) Director(a), es momento de que los Nuevos Anfitriones
              comiencen a agrecer a sus <br />
              Validadores.
            </Paragraph>
            <Paragraph align="center" size="sx">
              Mañana en el Día de Inducción, iniciarán la Convocatoria.
              Requerirán el link de registro al <br /> EPA.
            </Paragraph>
            <Paragraph align="center" size="sx">
              A continuación vas a generar el link de la convocatoria que usarán
              para aplicar el ABC. Recuerda que los Anfitriones podrán acceder
              al enlace que generaste desde su perfil en la <br /> Plataforma
              ACDC.
            </Paragraph>
            <br />
            <br />
            <br />
            <br />
            <Button className="director" onClick={() => setStep(step + 1)}>
              Generar Links
            </Button>
          </Step>
          <Modal
            isOpen={step === 15}
            className="host-alignment-day-container epa-confirmation-modal payment-modal"
          >
            <span className="title">¡Súper!</span>
            <Paragraph size="sx" align="center">
              El link “ha sido generado”.
              <br />
              (Entre “ “ por esto es una simulación) <br />
              Tus Nuevos Anfitriones “podrán acceder a <br /> él” para convocar
              a sus validadores y que se <br />
              agenden al EPA en la fecha y hora <br /> acordadas.
            </Paragraph>
            <Button onClick={() => setStep(step + 1)} size="small">
              ¡Vale!
            </Button>
            <br />
          </Modal>
          <Step
            order={16}
            visibility={step === 16 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <br />
            {captainAndHosts &&
              captainAndHosts.map(
                (director) =>
                  director?._id === user?._id &&
                  director?.children?.map((item) => (
                    <>
                      <div className="user-container-hungry">
                        <div className="user-card-hungry">
                          <div className="user-header-hungry">
                            <img
                              src={NewCoordinator}
                              alt="Paula Inés"
                              width={80}
                              height={80}
                              className="avatar-hambre"
                            />
                            <div className="user-details-hungry">
                              <span className="user-name-hungry">
                                {toLowercaseAndCapitalize(item.name)}
                              </span>
                              <p className="user-role-hungry">
                                Nuevo(a) Coordinador(a)
                              </p>
                            </div>
                          </div>
                          <div className="arrow-hungry">
                            <img
                              src={ArrowSmall}
                              alt="arrow-small"
                              className="arrow-small"
                            />
                          </div>
                        </div>
                        {item?.schedule && item.schedule[7] && (
                          <>
                            <div className="epa-section-hungry">
                              <div className="epa-title-hungry">EPA:</div>
                              <div className="card-body-epa">
                                <input
                                  type="text"
                                  className="epa-hungry-date"
                                  value={item?.schedule[7]?.date}
                                  disabled
                                />
                                <input
                                  type="text"
                                  className="epa-hungry-hour"
                                  value={item?.schedule[7]?.hour}
                                  disabled
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {item.children?.map((host) => (
                          <>
                            <div className="user-card-h">
                              <div className="user-header-h">
                                <img
                                  src={NewHost}
                                  alt="Luis David"
                                  width={80}
                                  height={80}
                                  className="avatar-h"
                                />
                                <div className="user-info-h">
                                  <span className="user-name-h">
                                    {host.name}
                                  </span>
                                  <p className="user-role-h">
                                    Nuevo(a) Anfitrión(a)
                                  </p>
                                </div>
                              </div>

                              <div className="task-section-h">
                                <div className="task-h">
                                  <span className="tastk-span">
                                    Entrenamiento completado
                                  </span>

                                  <div className="checkbox-container-h">
                                    <div className="user-status-checkbox">
                                      <div className="status-check-checkbox">
                                        {host.completedTrainingOfKeyA ? (
                                          <img src={CheckoutList} alt="" />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="task-h">
                                  <span className="tastk-span">
                                    Agradecimientos Realizados:
                                  </span>
                                  <span className="task-count-h">
                                    {host?.gratitudeDoneCount || 0}
                                  </span>
                                </div>
                              </div>

                              <button
                                className="validate-button-h"
                                onClick={() => {
                                  handlerValidators(host);
                                  setModalValidate(true);
                                }}
                              >
                                Ver validadores
                              </button>
                            </div>
                            <br />
                          </>
                        ))}
                      </div>
                    </>
                  ))
              )}
            <br />
            <br />
            <br />
            <br />
            <Button
              onClick={() => {
                setStep(step + 1);
                setChangeRole(user?.role);
                setChangeDay("Día de Alinamiento");
              }}
            >
              Siguiente
            </Button>
          </Step>
          <Modal
            onClose={() => {
              setModalConfirmation(false);
              setModalValidate(false);
            }}
            isOpen={modalValidate}
            className="agreements-checkbox-modal"
          >
            <br />

            <span
              className="close icon-close close-new-director"
              onClick={() => {
                setModalConfirmation(false);
                setModalValidate(false);
              }}
              onKeyDown={() => {}}
            ></span>
            <div className="lista-validadores">
              {userInfo &&
                userInfo?.map((item, index) => (
                  <>
                    <div
                      className="validador"
                      onClick={() => {
                        setModalValidate(false);
                      }}
                    >
                      {" "}
                      <div className="checkbox-validador"></div>
                      <div className="nombre-validador">{item.name}</div>
                    </div>
                  </>
                ))}
            </div>
          </Modal>
          <Step
            order={17}
            visibility={step === 17 ? "visible" : "hidden"}
            className="filter-day-container"
          >
            <span className="title">
              Fin de la Zona de Aprendizaje del EPA.
            </span>
            <br />
            <img
              src={CheckHand}
              alt="epa-schedule-hand"
              width="246"
              height="246"
            />
            <Paragraph size="sx" align="center">
              Excelente!
              <br />
              <br />
              <span style={{ color: "green" }}>
                Ya estas preparado(a) para asumir tus responsabilidades como
                Nuevo(a) Director(a). Recuerda cuidadosamente lo que has
                aprendido y aplícalo mientras enfrentas los desafíos que tendrás
                en el EPA.
              </span>
            </Paragraph>
            <Button
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Continuar
            </Button>
          </Step>
          <Step order={18} visibility={step === 18 ? "visible" : "hidden"}>
            <Paragraph size="sx" align="center">
              Hoy cuando completes tu Meta 2 al finalizar el
              <br />
              Alineamiento y estén confirmados los asistentes
              <br />
              del EPA, deberás realizar la compra de <br /> productos que te
              habilitan como líder en ACDC.
            </Paragraph>
            <Paragraph size="sx" align="center">
              Vas a comprar tu paquete de inicio, este <br />
              paquete te permitirá activar un activo financiero
              <br /> que comercializa productos que tienen <br /> disponibilidad
              en más de 80 países.
            </Paragraph>

            <div className="warning-card  warning-card-box ">
              <img
                src={WarningIcon}
                alt="warning"
                className="warning "
                style={{
                  marginBottom: "20px",
                }}
                width={98}
                height={93}
              />

              <Paragraph
                size="sx"
                align="center"
                style={{
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                Coordinador(a), recuerda que hoy debes
                <br />
                tener listo el dinero que requieres para <br />
                realizar tu compra del paquete de inicio.
              </Paragraph>
              <br />
            </div>

            <Button onClick={() => setStep(step + 1)}>Aceptar</Button>
          </Step>
          <Step order={19} visibility={step === 19 ? "visible" : "hidden"}>
            <br />
            <Paragraph size="s" align="center">
              {dataQuestionOptions[0]?.question}
            </Paragraph>
            {dataQuestionOptions?.[0]?.options?.map((option, index) => (
              <Checkbox
                key={index}
                name="binary-bonus"
                label={option.option}
                checked={
                  (option.status = selectedZero === index ? true : false)
                }
                className=""
                style={{
                  width: "288px",
                  padding: "0px 0px 0px 35px",
                  marginTop: "49px",
                }}
                onChange={() => {
                  setSelectedZero(selectedZero === index ? null : index);
                  setSelectedIndex(index + 1);
                }}
              />
            ))}

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button onClick={() => setStep(step + 1)}>Aceptar</Button>
          </Step>
          <Modal
            isOpen={selectedIndex !== 0 && selectedIndex < 5}
            className="confirmation-register-modal"
          >
            <div className="confirmation-register-container">
              <div className="light">
                <img src={WarningIcon} alt="Warning" />
              </div>
              <Paragraph align="center" size="s">
                {selectedIndex === 1 ? (
                  <>
                    ¡Asegúrate que tu cuenta esté habilitada
                    <br /> para transacciones virtuales!
                  </>
                ) : selectedIndex === 2 ? (
                  <>
                    ¡Asegúrate que tu tarjeta de débito esté
                    <br />
                    habilitada para transacciones virtuales!
                  </>
                ) : selectedIndex === 3 ? (
                  <>
                    ¡Asegúrate de que tu tarjeta esté vigente
                    <br /> y cuentes con el cupo disponible! A todos
                    <br /> nos puede pasar.
                  </>
                ) : selectedIndex === 4 ? (
                  <>
                    ¡Asegúrate de contar con el tiempo
                    <br />
                    suficiente para consignar y enviar el
                    <br />
                    soporte antes del EPA!
                  </>
                ) : (
                  ""
                )}
              </Paragraph>
              <Button size="small" onClick={() => setSelectedIndex(0)}>
                ¡Vale!
              </Button>
            </div>
          </Modal>

          <Step order={20} visibility={step === 20 ? "visible" : "hidden"}>
            <Paragraph size="sx" align="center">
              <b>¡Coordinador(a)!</b>
              <br />
              ¿Cuándo y a qué hora vas a realizar la compra <br /> de tu paquete
              de inicio?¿Cuánto vas a pagar?
            </Paragraph>

            <div className="purchase-info">
              <label>Fecha de la compra:</label>
              <div className="info-item">
                <div className="icon">
                  <img src={Calendar1} alt="Icono de calendario" />
                </div>

                <div
                  className="details"
                  onClick={(e) => setIsDatePickerBuyOpen(true)}
                >
                  {dateBuySelected ? formatearDate(dateEdit) : "Placeholder"}
                </div>
              </div>
              <label>Hora de la compra:</label>
              <div className="info-item">
                <div className="icon">
                  <img src={clock2} alt="Icono de reloj" />
                </div>
                <div
                  className="details"
                  onClick={(e) => setIsTimePickerBuyOpen(true)}
                >
                  {timeBuySelected ? getHoraAmAndPm(timeEdit) : "Placeholder"}
                </div>
              </div>
              <label>Monto:</label>
              <div className="info-item">
                <div className="icon">
                  <img src={cash2} alt="Icono de dinero" />
                </div>
                <div
                  className="details"
                  style={{
                    width: "150px",
                  }}
                >
                  <span>
                    <input
                      type="text"
                      name="price"
                      placeholder="placeholder"
                      value={monyEdit}
                      onChange={(e) => handleDateChangeBuy(e.target.value)}
                      style={{
                        backgroundColor: " #13253b",
                        border: "none",
                        width: "130px",
                        color: "white",
                        padding: "5px 10px",
                        fontSize: "14px",
                        borderRadius: "5px",
                        height: "34px",
                      }}
                    />
                  </span>
                  <span
                    style={{
                      marginLeft: "204px",
                      marginTop: "-41px",
                    }}
                  >
                    COP
                  </span>
                </div>
              </div>
            </div>

            <CustomDatePicker
              isOpen={isDatePickerBuyOpen}
              onSelect={handleDateChangeBuyEdit}
              time={dateEdit}
              min={
                new Date(
                  dateEdit.getFullYear(),
                  dateEdit.getMonth(),
                  dateEdit.getDate() + 8
                )
              }
              max={
                new Date(
                  dateEdit.getFullYear(),
                  dateEdit.getMonth(),
                  dateEdit.getDate() + 15
                )
              }
              role="coordinator"
              onCancel={() => setIsDatePickerBuyOpen(false)}
            />
            <CustomDatePicker
              isOpen={isTimePickerBuyOpen}
              onSelect={handleTimeChangeBuyEdit}
              time={timeEdit}
              role="coordinator"
              onCancel={() => setIsTimePickerBuyOpen(false)}
              isTimePicker
            />

            <br />
            <br />
            <br />
            <br />
            <Button
              disabled={timeBuySelected || dateBuySelected ? false : true}
              onClick={() => handlerSaveBuy(user?._id)}
            >
              Aceptar
            </Button>
          </Step>
          <Step
            order={21}
            visibility={step === 21 ? "visible" : "hidden"}
            className="rules-day-container"
          >
            <Paragraph align="center" size="s">
              ¡Genial!
              <br />
              <br />
              Has completado el Desafío B.
              <img
                src={GoodHandClap}
                alt="good-hand-coordinator"
                style={{ marginTop: "10px" }}
              />
            </Paragraph>
            <CardCopyKey
              phrase="Sync Alineamiento Coordinador"
              nameChallenge="Desafío B"
            />
            <Button
              onClick={() => {
                saveResponse();
              }}
            >
              Finalizar
            </Button>
          </Step>
        </>
      )}

      {challengeName === "Desafío C" && (
        <>
          <Step
            order={1}
            visibility={step === 1 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="sx" align="center">
              A continuación, encontrarás la hora y el link de la Meet a la que
              deberás conectarte para realizar una sincronización de 10 minutos
              con el resto del equipo.
            </Paragraph>
            <img src={coordinatorSync} alt="" width="100" height="100" />
            <CardCopyKey
              phrase="meet//:a923nr42098nf"
              nameChallenge="Desafío B"
            />
            <Button className="coordinator" onClick={() => setStep(step + 1)}>
              Continuar
            </Button>
          </Step>
          <Step
            order={2}
            visibility={step === 2 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="sx" align="center">
              <b>Requisitos de participación en un EPA.</b>
            </Paragraph>
            <Paragraph size="sx" align="center">
              Estos son los primeros tres requisitos que se encuentran
              confirmando los(as) Aspirantes en este momento:
            </Paragraph>
            <Paragraph size="sx" className="card-list-alignament">
              <span className="num-list">1.</span>{" "}
              <span className="text-list">
                Disposición de 2 Horas para participar enfocado(a) en el EPA y
                sin interrupciones.
              </span>
            </Paragraph>
            <Paragraph size="sx" className="card-list-alignament">
              <span className="num-list">2.</span>
              <span className="text-list">
                {" "}
                Tener computador y celular cargados, audífonos y buena conexión
                a internet.
              </span>
            </Paragraph>
            <Paragraph size="sx" className="card-list-alignament">
              <span className="num-list">3.</span>
              <span className="text-list">
                {" "}
                Aceptar el Acuerdo de Confidencialidad.
              </span>
            </Paragraph>
            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>
          <Step
            order={3}
            visibility={step === 3 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="s" align="center">
              Visualiza cómo van los(as) Aspirantes confirmando los primeros 3
              requisitos que te mostramos en la pantalla anterior:
            </Paragraph>
            {dataDirector &&
              dataDirector?.map((coordinator) =>
                coordinator.children.map((host) =>
                  host?.children.map((item) => (
                    <>
                      <div className="user-card user-card-host-by-coordinator">
                        <div className="user-info">
                          <img
                            src={NewHost}
                            alt="new-host"
                            width={80}
                            height={80}
                            className="user-avatar"
                          />

                          <div className="user-details">
                            <span className="user-name">{item.name}</span>
                            <div className="user-status">
                              <span className="status-dot"></span>
                              <span className="status-text">
                                Nuevo(a) Anfitrión(a)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {item.children.map((aspirant) => {
                        const keys = [
                          "provisionTwoHours",
                          "connectionTools",
                          "confidentialityAgreement",
                          "virtualSessions",
                          "minutesByWhatsApp",
                        ];
                        const checks = keys.map(
                          (key) => aspirant.epaParticipationRequirements?.[key]
                        ) || [false, false, false, false, false];
                        return (
                          <>
                            <div
                              className={`avatar-challenges-marked  candidate`}
                            >
                              <div
                                className={`menu-user-info menu-user-info--candidate `}
                              >
                                <div
                                  className={`menu-user-info-avatar progress-${7}-8 `}
                                >
                                  <AvatarMenu />
                                </div>
                                <div
                                  className="menu-user-info-title"
                                  style={{
                                    fontWeight: "100!important",
                                    fontSize: "16px",
                                    color: "#FFF",
                                    width: "247px",
                                  }}
                                >
                                  <span
                                    className={`name-user-candidate`}
                                    style={{
                                      color: "#FFF!important",
                                    }}
                                  >
                                    {aspirant.name}
                                  </span>
                                  <span>
                                    {`⚫ ${ROLES["candidate"].roleName}`}{" "}
                                  </span>
                                </div>
                              </div>

                              {checks?.length > 0 && (
                                <div className="avatar-challenges-marked__checks">
                                  {checks?.map((check, index) => (
                                    <>
                                      <span>{index + 1}.</span>
                                      <Checkbox
                                        checked={check}
                                        onChange={() => {}}
                                      />
                                    </>
                                  ))}
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </>
                  ))
                )
              )}

            <Button className="coordinator" onClick={() => setStep(step + 1)}>
              Continuar
            </Button>
          </Step>
          <Step
            order={4}
            visibility={step === 4 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="sx" align="center">
              <b>Requisitos de participación en un EPA.</b>
            </Paragraph>

            <Paragraph size="sx" align="center">
              Estos son los dos requisitos finales que se <br />
              encuentran confirmando los(as) Aspirantes en este momento:
            </Paragraph>

            <Paragraph size="sx" className="card-list-alignament">
              <span className="num-list">4.</span>
              <span className="text-list">
                {" "}
                4 sesiones virtuales de trabajo en equipo de 2 horas cada una. a
                internet.
              </span>
            </Paragraph>
            <Paragraph size="sx" className="card-list-alignament">
              <span className="num-list">5.</span>
              <span className="text-list">
                15 minutos diarios por WhatsApp.
              </span>
            </Paragraph>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button
              style={{
                marginTop: "106px",
              }}
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>
          <Step
            order={5}
            visibility={step === 5 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="s" align="center">
              Visualiza cómo van los(as) Aspirantes confirmando los últimos DOS
              requisitos:
            </Paragraph>
            {dataDirector &&
              dataDirector?.map((coordinator) =>
                coordinator.children.map((host) =>
                  host?.children.map((item) => (
                    <>
                      <div className="user-card  user-card-host-by-coordinator">
                        <div className="user-info">
                          <img
                            src={NewHost}
                            alt="new-host"
                            width={80}
                            height={80}
                            className="user-avatar"
                          />

                          <div className="user-details">
                            <span className="user-name">{item.name}</span>
                            <div className="user-status">
                              <span className="status-dot"></span>
                              <span className="status-text">
                                Nuevo(a) Anfitrión(a)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {item.children.map((aspirant) => {
                        const keys = [
                          "provisionTwoHours",
                          "connectionTools",
                          "confidentialityAgreement",
                          "virtualSessions",
                          "minutesByWhatsApp",
                        ];
                        const checks = keys.map(
                          (key) => aspirant.epaParticipationRequirements?.[key]
                        ) || [false, false, false, false, false];
                        return (
                          <>
                            <div
                              className={`avatar-challenges-marked  candidate`}
                            >
                              <div
                                className={`menu-user-info menu-user-info--candidate `}
                              >
                                <div
                                  className={`menu-user-info-avatar progress-${7}-8 `}
                                >
                                  <AvatarMenu />
                                </div>
                                <div
                                  className="menu-user-info-title"
                                  style={{
                                    fontWeight: "100!important",
                                    fontSize: "16px",
                                    color: "#FFF",
                                    width: "247px",
                                  }}
                                >
                                  <span
                                    className={`name-user-candidate`}
                                    style={{
                                      color: "#FFF!important",
                                    }}
                                  >
                                    {aspirant.name}
                                  </span>
                                  <span>
                                    {`⚫ ${ROLES["candidate"].roleName}`}{" "}
                                  </span>
                                </div>
                              </div>

                              {checks?.length > 0 && (
                                <div className="avatar-challenges-marked__checks">
                                  {checks?.map((check, index) => (
                                    <>
                                      <span>{index + 1}.</span>
                                      <Checkbox
                                        checked={check}
                                        onChange={() => {}}
                                      />
                                    </>
                                  ))}
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </>
                  ))
                )
              )}

            <Button className="coordinator" onClick={() => setStep(step + 1)}>
              Continuar
            </Button>
          </Step>

          <Step
            order={6}
            visibility={step === 6 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <Paragraph size="s" align="center">
              <b>¡Excelente</b>
              <br />
              <br />
              Estos son los preseleccionados
              <br />
              de cada Anfitrión:
            </Paragraph>
            {dataDirector &&
              dataDirector?.map((coordinator) =>
                coordinator.children.map((host) =>
                  host?.children.map((item,index) => (
                    <>
                      <div className="user-card user-card-host-by-coordinator">
                        <div className="user-info">
                          <img
                            src={NewHost}
                            alt="new-host"
                            width={80}
                            height={80}
                            className="user-avatar"
                          />

                          <div className="user-details">
                            <span className="user-name">{item.name}</span>
                            <div className="user-status">
                              <span className="status-dot"></span>
                              <span className="status-text">
                                Nuevo(a) Anfitrión(a)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                      
                   
                      item.children.map((aspirant) => {
                        const keys = [
                          "provisionTwoHours",
                          "connectionTools",
                          "confidentialityAgreement",
                          "virtualSessions",
                          "minutesByWhatsApp",
                        ];
                        const checks = keys.map(
                          (key) => aspirant.epaParticipationRequirements?.[key]
                        ) || [false, false, false, false, false];
                        return (
                          <>
                            <div className="user-card candidate-card">
                              <div className="user-info">
                                <img
                                  src={Candidate}
                                  alt="candidate"
                                  width={80}
                                  height={80}
                                  className="user-avatar"
                                />

                                <div className="user-details">
                                  <span className="user-name">
                                    {aspirant.name}
                                  </span>
                                  <div className="user-status">
                                    <span className="status-text">
                                      {`⚫ ${ROLES["candidate"].roleName}`}{" "}
                                      Aspirante
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                      
                      }
                    </>
                  ))
                )
              )}
            <div className="user-card candidate-card">
              <div className="user-info">
                <img
                  src={CandidateBlack}
                  alt="candidate-black"
                  width={80}
                  height={80}
                  className="user-avatar"
                />

                <div className="user-details">
                  <span className="user-name">¡Ups! falta un Aspirante</span>
                  <div className="user-status">
                    <span
                      className="status-text add-candidate-text"
                      onClick={() => setOpen(true)}
                    >
                      Agregar de lista de espera
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Button
              styles={{
                position: "relative",
                marginTop: "76px",
              }}
              onClick={() => setOpen(true)}
            >
              Continuar
            </Button>
          </Step>
          <Modal
            isOpen={open}
            onClose={() => setOpen(false)}
            className="list-modal"
          >
            <span className="close icon-close" onClick={()=>setOpen(false)}></span><span
                className="close icon-close"
                onClick={() => setOpen(false)}
                onKeyDown={handleKeyDown}
              ></span>
              {currentSlide === 0 && (
                <>
                  <span className="title">
                    {titleRoleCoordinatorDiretor[0]}
                  </span>
                  <ul className="list-modal--list">
                    {user.personalReferences?.map((item, index) =>
                      item.processStatus === "waiting list" ? (
                        <li
                          key={`unique${item.token}`}
                          className={`list-modal--list--item ${
                            selectedCandidates.includes(item.token)
                              ? "color-gray"
                              : ""
                          }`}
                          onClick={() => {
                            if (!selectedCandidates.includes(item.token)) {
                              selectedCandidate(item.token);
                              setHostByPersonalReferences((prevState) => ({
                                ...prevState,
                                selectedIdHost: [
                                  ...(prevState?.selectedIdHost || []),
                                  {
                                    idHost: selectedIdHost,
                                    id: item.token,
                                    name: item.name,
                                  },
                                ],
                              }));
                            }
                          }}
                        >
                          {item.name}

                          {selectedCandidates.includes(item.token) &&
                          hostByPersonalReferences.selectedIdHost?.some(
                            (host) =>
                              host.idHost === selectedIdHost &&
                              host.id === item.token
                          ) ? (
                            <img
                              src={CheckList}
                              alt=""
                              width="20"
                              height="15"
                              style={{
                                marginLeft: "40px",
                                marginTop: "5px",
                                width: "15px",
                                height: "15px",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedCandidates((prev) =>
                                  prev.filter(
                                    (candidate) => candidate !== item.token
                                  )
                                );

                                setHostByPersonalReferences((prevState) => ({
                                  ...prevState,
                                  selectedIdHost:
                                    prevState?.selectedIdHost?.filter(
                                      (host) => host.id !== item.token
                                    ) || [],
                                }));
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </li>
                      ) : null
                    )}
                  </ul>
                </>
              )}
              {currentSlide === 1 && (
                <>
                  <span className="title">
                    {titleRoleCoordinatorDiretor[1]}
                  </span>
                  <ul className="list-modal--list">
                    {director.personalReferences?.map((item, index) =>
                      item.processStatus === "waiting list" ? (
                        <li
                          key={`unique${item.token}`}
                          className={`list-modal--list--item ${
                            selectedCandidates.includes(item.token)
                              ? "color-gray"
                              : ""
                          }`}
                          onClick={() => {
                            if (!selectedCandidates.includes(item.token)) {
                              selectedCandidate(item.token);
                              setHostByPersonalReferences((prevState) => ({
                                ...prevState,
                                selectedIdHost: [
                                  ...(prevState?.selectedIdHost || []),
                                  {
                                    idHost: selectedIdHost,
                                    id: item.token,
                                    name: item.name,
                                  },
                                ],
                              }));
                            }
                          }}
                        >
                          {item.name}

                          {selectedCandidates.includes(item.token) &&
                          hostByPersonalReferences.selectedIdHost?.some(
                            (host) =>
                              host.idHost === selectedIdHost &&
                              host.id === item.token
                          ) ? (
                            <img
                              src={CheckList}
                              alt=""
                              width="20"
                              height="15"
                              style={{
                                marginLeft: "40px",
                                marginTop: "5px",
                                width: "15px",
                                height: "15px",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedCandidates((prev) =>
                                  prev.filter(
                                    (candidate) => candidate !== item.token
                                  )
                                );

                                setHostByPersonalReferences((prevState) => ({
                                  ...prevState,
                                  selectedIdHost:
                                    prevState?.selectedIdHost?.filter(
                                      (host) => host.id !== item.token
                                    ) || [],
                                }));
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </li>
                      ) : null
                    )}
                  </ul>
                </>
              )}
                 {currentSlide === 2 && (
                <>
                  <span className="title">
                    {titleRoleCoordinatorDiretor[2]}
                  </span>
                  <ul className="list-modal--list">
                    {director.personalReferences?.map((item, index) =>
                      item.processStatus === "waiting list" ? (
                        <li
                          key={`unique${item.token}`}
                          className={`list-modal--list--item ${
                            selectedCandidates.includes(item.token)
                              ? "color-gray"
                              : ""
                          }`}
                          onClick={() => {
                            if (!selectedCandidates.includes(item.token)) {
                              selectedCandidate(item.token);
                              setHostByPersonalReferences((prevState) => ({
                                ...prevState,
                                selectedIdHost: [
                                  ...(prevState?.selectedIdHost || []),
                                  {
                                    idHost: selectedIdHost,
                                    id: item.token,
                                    name: item.name,
                                  },
                                ],
                              }));
                            }
                          }}
                        >
                          {item.name}

                          {selectedCandidates.includes(item.token) &&
                          hostByPersonalReferences.selectedIdHost?.some(
                            (host) =>
                              host.idHost === selectedIdHost &&
                              host.id === item.token
                          ) ? (
                            <img
                              src={CheckList}
                              alt=""
                              width="20"
                              height="15"
                              style={{
                                marginLeft: "40px",
                                marginTop: "5px",
                                width: "15px",
                                height: "15px",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedCandidates((prev) =>
                                  prev.filter(
                                    (candidate) => candidate !== item.token
                                  )
                                );

                                setHostByPersonalReferences((prevState) => ({
                                  ...prevState,
                                  selectedIdHost:
                                    prevState?.selectedIdHost?.filter(
                                      (host) => host.id !== item.token
                                    ) || [],
                                }));
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </li>
                      ) : null
                    )}
                  </ul>
                </>
              )}
              <div className="carousel-controls">
                <div
                  className={`carousel-control carousel-control--prev ${
                    currentSlide === 0 ? "disabled" : ""
                  }`}
                  onClick={() => handleNavigation(-1)}
                  onKeyDown={() => {}}
                >
                  <span className="icon icon-previous"></span>
                </div>
                <ul className="carousel-control carousel-control--pagination">
                  <li
                    key={0}
                    className={`item ${0 === currentSlide ? "active" : ""}`}
                  ></li>
                  <li
                    key={1}
                    className={`item ${1 === currentSlide ? "active" : ""}`}
                  ></li>
                   <li
                    key={2}
                    className={`item ${2 === currentSlide ? "active" : ""}`}
                  ></li>
                </ul>
                <div
                  className={`carousel-control carousel-control--next ${
                    currentSlide === 3 - 1 ? "disabled" : ""
                  }`}
                  onClick={() => handleNavigation(1)}
                  onKeyDown={() => {}}
                >
                  <span className="icon icon-next"></span>
                </div>
              </div>
          </Modal>
          <Step
            order={7}
            visibility={step === 7 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <br />
            <br />
            <br />
            <img src={whatsApp} alt=" whatsapp" width="85" height="86" />
            <br />
            <br />
            <br />
            <Paragraph size="sx" align="center">
              En este momento tus Anfitriones deben agregar al grupo del EPA a
              sus Aspirantes preseleccionados.
            </Paragraph>
            <br />
            <Paragraph size="sx" align="center">
              . En caso de que estos sean de tu Lista de Espera, los debes
              agregar tú.
            </Paragraph>
            <br />
            <Paragraph size="sx" align="center">
              . En caso de que estos sean de la Lista de Espera de tu
              Director(a), los debe agregar el
              <br /> Director(a).
            </Paragraph>

            <Button onClick={() => setStep(step + 1)}>Continuar</Button>
          </Step>
          <Step
            order={8}
            visibility={step === 8 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <br />
            <br />
            <br />
            <img src={whatsAppGroup} alt=" whatsapp" width="220" height="100" />
            <br />
            <br />
            <br />
            <Paragraph size="sx" align="center">
              En este momento debes crear dos nuevos grupos de WhatsApp.
            </Paragraph>
            <br />
            <Paragraph size="sx" align="center">
              Agrega a un(una) Anfitrión(a) en cada grupo, en estos grupos tú
              serás el(la) Nuevo(a) Director(a), tus Anfitriones serán tus
              Nuevos Coordinadores, y al final del EPA cada uno de ellos
              agregará sus dos Nuevos Anfitriones.
            </Paragraph>
            <br />

            <Button
              className="rules-day-container-button"
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>
          </Step>

          <Step
            order={9}
            visibility={step === 9 ? "visible" : "hidden"}
            className="host-alignment-day-container"
          >
            <br />
            <br />
            <br />
            <img src={Treesdicons} alt=" whatsapp" width="194" height="160" />
            <br />

            <Paragraph size="sx" align="center">
              En este momento debes sincronizarte con tu Director(a) para
              efectuar la compra de tu paquete de inicio, podrás continuar
              cuando tu Director(a) confirme que efectuaste el pago.
            </Paragraph>
            <br />

            <br />

            <Paragraph
              size="sx"
              align="center"
              style={{
                border: "solid 1px blue",
                height: "78px",
                borderRadius: "6px",
                paddingTop: "15px",
              }}
            >
              Manual de paso a paso para la compra del Paquete de Inicio.
            </Paragraph>
            <br />

            <br />
            <a
              className="button button--small rules-day-container-button "
              download="Manual_de_registro_Gano_Excel.pdf"
              href="/Manual de registro Gano Excel.pdf"
              style={{
                margin: " -42px 0px 11px 20px",
                height: "21px",
                textDecoration: "none",
                display: " flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              Ver Manual
            </a>

            <Button
              className="rules-day-container-button coordinator"
              onClick={() => setStep(step + 1)}
            >
              Continuar
            </Button>

            <Button
              id="game-over"
              onClick={() => setGameOverModal(true)}
              style="abort"
              size="small"
              styles={{
                textDecoration: "underline",
              }}
            >
              Game Over
            </Button>
          </Step>
          <Step order={10} visibility={step === 10 ? "visible" : "hidden"}>
            <Paragraph size="s" align="center">
              <b>Genial!</b>
              <br />
              Has completado el Desafío C.
            </Paragraph>
            <img src={GoodHandClap} alt="Good Hand" className="good-hand" />
            <Card type="secondary" outline>
              <div className="epa-schedule-card">
                <Paragraph align="center" size="s">
                  Copia la clave que deberás pegar y enviar a TWOBOT para
                  continuar:
                </Paragraph>
                <div className="epa-schedule-card__key">
                  Cierre Alineamiento Coordinador
                </div>
                <CopyField
                  value="Cierre Alineamiento Coordinador"
                  setStep={() => {
                    setCanFinish(true);
                  }}
                />
                <Paragraph align="center" size="xs" padding={16}>
                  Podrás copiar la clave en cualquier momento desde la{" "}
                  <span className="icon-info"></span> información del{" "}
                  <b>Desafío C</b> en
                  <b>Mi Progreso</b>
                </Paragraph>
              </div>
            </Card>
            <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
          </Step>
        </>
      )}
    </>
  );
};

export default CoordinatorAlignmentDay;
